class Util {
  constructor() {
  }
  getMode() {
    return {
      1: "mobile",
      2: "tablet"
    }[document.getElementById("state-indicator").style.zIndex] || "desktop"
  }
  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
  getTransformValues(element) {
    var style = window.getComputedStyle(element),
        i = new WebKitCSSMatrix(style.webkitTransform);
    return {
        x: i.m41,
        y: i.m42
    }
  }
  getViewport() {
    return {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
        outerWidth: window.innerWidth,
        outerHeight: window.innerHeight
    }
  }
  guid() {
    return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase()
  }
  parseHashUrl() {
    for (var t, e = {}, i = /\+/g, r = /([^&=]+)=?([^&]*)/g, n = function(t) {
            return decodeURIComponent(t.replace(i, " "))
        }, o = window.location.hash.substring(1); t = r.exec(o);) e[n(t[1])] = n(t[2]);
    return e
  }
  setScroll(t, e) {
    window.scrollTo(t, e)
  }
  resetScroll() {
    // window.scrollTo(0, 0)
    //
    // var urlHash = location.hash;
    // if($(urlHash).length){
    //   setTimeout(function(){
    //     let _target = $(urlHash);
    //     let _offsetY = 0;
    //     // 移動先となる値
    //     let _targetY = _target.offset().top+_offsetY;
    //     window.scrollTo(0, _targetY)
    //   },100);
    // }
    //
    // //URLのアンカー（?以降の部分）を取得、加工してアンカーに移動する
    // var urlSearch = location.search.match(/anchor=(.*?)(&|$)/);
    // if(urlSearch != null){
    //   if($('#'+urlSearch[1]).length){
    //     setTimeout(function(){
    //       let _target = $('#'+urlSearch[1]);
    //       let _offsetY = 0;
    //       // 移動先となる値
    //       let _targetY = _target.offset().top+_offsetY;
    //       window.scrollTo(0, _targetY)
    //     },100);
    //   }
    // }
  }
  resetContext() {
    emitter.on("statechange::before", function() {})
  }
  scrollPosition() {
    var t = this.getViewport(),
        e = Math.round(inheritance.$master.getBoundingClientRect().height - t.height);
    return {
        scrollY: document.scrollingElement && document.scrollingElement.scrollTop ? document.scrollingElement.scrollTop : document.documentElement && document.documentElement.scrollTop ? document.documentElement.scrollTop : inheritance.$body.scrollTop,
        fullPageHeight: e
    }
  }
  wheelSupport() {
    return "onwheel" in document.createElement("div") ? "wheel" : void 0 !== document.onmousewheel ? "mousewheel" : "DOMMouseScroll"
  }
  lerp(from, to, alpha) {
    return from * (1 - alpha) + to * alpha;
  }
  map(val, toMin, toMax, fromMin, fromMax) {
    if(val <= fromMin) {
      return toMin;
    }
    if(val >= fromMax) {
      return toMax;
    }
    var p = (toMax - toMin) / (fromMax - fromMin);
    return ((val - fromMin) * p) + toMin;
  };
}
module.exports = Util;