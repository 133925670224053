export default class MagazineList{

  constructor(){
    this.$target = $('.j-magazine-list');
    if(this.$target.length){
      this.init();
    }
  }

  init(){
    this.$btn = this.$target.find('.j-button-more');
    this.$btn.on('click.MagazineList', () => {
      this.showPC();
      this.showSP();
      return false;
    });
  }

  showPC(){
    let $item = this.$target.find('.j-magazine-list-item.is-hidden:lt(10)');
    $item.removeClass('is-hidden');
    setTimeout(() => {
      $item.addClass('is-show-pc');
    }, 10);

    let $itemF = this.$target.find('.j-magazine-list-item.is-hidden');
    if(!$itemF.length){
      this.$btn.filter('.is-pc').addClass('is-hidden');
    }
  }

  showSP(){
    let $item = this.$target.find('.j-magazine-list-item.is-hidden-sp:lt(5)');
    $item.removeClass('is-hidden-sp');
    setTimeout(() => {
      $item.addClass('is-show-sp');
    }, 10);

    let $itemF = this.$target.find('.j-magazine-list-item.is-hidden-sp');
    if(!$itemF.length){
      this.$btn.filter('.is-sp').addClass('is-hidden');
    }
  }

}