export default class BookSec{

  constructor(){
    this.$target = $('.j-book-sec');
    if(this.$target.length){
      this.init();
    }
  }

  init(){
    this.$swiper = this.$target.find('.j-swiper');


    if(window.matchMedia('only screen and (max-width: 767px)').matches){
      this.setupSP();
    }else{
      this.setupPC();
    }
  }

  setupSP(){
    this.swiper = new Swiper(this.$swiper.get(0), {
      slidesPerView: 'auto',
      spaceBetween: 0,
      freeMode: true
    });
  }

  setupPC(){
    this.swiper = new Swiper(this.$swiper.get(0), {
      slidesPerView: 4,
      spaceBetween: 0,
      freeMode: true
    });
  }

}