import MobileDetect from 'MobileDetect';

export default class deviceState {
  constructor() {
    var e = this;
    this.detect();
    this.orientation();
    emitter.on("window::smartresize", function() {
      e.detect(),
      e.orientation()
    })
  }
  detect() {
    var t = new MobileDetect(window.navigator.userAgent);
    inheritance.userAgent = t.userAgent();
    inheritance.desktop = false;
    inheritance.mobile = !!t.mobile();
    inheritance.phone = !!t.phone();
    inheritance.tablet = !!t.tablet();
    inheritance.ios = "iPhone" == t.mobile() == 1 || "iPad" == t.tablet() == 1;
    if(false == inheritance.mobile){
      inheritance.desktop = true
    }
    if(-1 !== navigator.appVersion.indexOf("MSIE 10")){
      inheritance.ie10 = true ;
      inheritance.$html.classList.add("isOldie");
      inheritance.isOldie = true;
    }
    if(1 == inheritance.tablet && window.innerWidth < window.innerHeight || 1 == inheritance.phone){
      inheritance.mobileCSS = true;
    }
    inheritance.ie11 = !!navigator.userAgent.match(/Trident\/7\./);
    if(1 == inheritance.ie11){
      inheritance.$html.classList.add("ie11")
      inheritance.$html.classList.add("isOldie");
      inheritance.isOldie = true;
    }
    inheritance.edge = window.navigator.userAgent.indexOf("Edge") > -1;
    inheritance.$html.classList.remove("desktop");
    inheritance.$html.classList.remove("tablet");
    inheritance.$html.classList.remove("mobile");
    1 == inheritance.desktop && inheritance.$html.classList.add("desktop");
    1 == inheritance.phone && inheritance.$html.classList.add("mobile");
    1 == inheritance.tablet && inheritance.$html.classList.add("tablet");

    //Androidの判定
    if (/Android/.test(t.ua)) {
      //標準ブラウザかどうか判定
      if ((/Android/.test(t.ua) && /Linux; U;/.test(t.ua) && !/Chrome/.test(t.ua)) || (/Android/.test(t.ua) && /Chrome/.test(t.ua) && /Version/.test(t.ua)) || (/Android/.test(t.ua) && /Chrome/.test(t.ua) && /SamsungBrowser/.test(t.ua))) {
          inheritance.playableInlineVideo = false;
      } else {
          //Chrome for Androidのバージョン取得
          var chromever = t.ua.match(/Chrome\/([\d]+)/).slice(1);
          //53以上か判定
          if(chromever >= 53){
              inheritance.playableInlineVideo = true;
          }
      }
    //iPhone-iPad-iPodの判定
  } else if ( inheritance.ios == true) {
      //iOSバージョンの取得
      // var iOSver = t.ua.match(/iP(hone|od|ad) OS ([\d]+)/).slice(2);
      var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
      var iOSver = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)]

      //iOS10以上か判定
      if(iOSver[0] >= 10){
        inheritance.playableInlineVideo = true;
      }
      if(iOSver[0] < 10){
        inheritance.isOldie = true;
        inheritance.$html.classList.add("isOldie")
      }
    //いずれにも該当しない場合
    } else {
      inheritance.playableInlineVideo = false;
    }

    if(t.os() == "AndroidOS"){
      var version = parseFloat(t.ua.slice(t.ua.indexOf("Android")+8))
      if(version < 5.2){
        inheritance.isOldie = true;
        inheritance.$html.classList.add("isOldie")
      }
    }
  }
  orientation() {
    inheritance.$html.classList.remove("landscape"),
    inheritance.$html.classList.remove("portrait"),
    window.innerWidth > window.innerHeight ? inheritance.orientation = "landscape" : inheritance.orientation = "portrait",
    inheritance.$html.classList.add(inheritance.orientation)
  }
}
// module.exports = deviceState;




// window.deviceState = deviceState; // 追加