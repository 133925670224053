import AOS from 'aos';
// import moment from 'moment';
import 'TweenMax';
import 'TweenLite';
import ScrollToPlugin from 'ScrollToPlugin';
import Util from './controllers/util';
import deviceState from './controllers/deviceState.js';
import Ui from './controllers/Ui';
import MagazineList from './controllers/magazineList';
import BookSec from './controllers/bookSec';
import isMobile from 'ismobilejs';

var events = require('events').EventEmitter;
window.emitter = new events.EventEmitter();

window.rAF = ( function(){
  return  window.requestAnimationFrame       ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame    ||
          window.oRequestAnimationFrame      ||
          window.msRequestAnimationFrame     ||
          function( callback ){
              window.setTimeout( callback, 1000.0 / 60.0 );
          };
} )();

window.inheritance = {
  $window: window,
  $html: document.getElementsByTagName("html")[0],
  $body: document.body,
  $master: document.getElementById("master"),
  framework: {},
}

const Front = ((window, undefined) => {
  let judgment = function (status, _m) {
    var format = 'HH:mm:ss'
    var time = moment(_m.format('HH:mm:ss'),format)
    var beforeTime = moment('9:00:00', format);
    var afterTime = moment('19:30:00', format);
    var beforeTimeSecond = moment('9:00:00', format);
    var afterTimeSecond = moment('12:00:00', format);

    var insertContentsOff = '';
    insertContentsOff += '<div class="Off-hours">';
    insertContentsOff += '  <p class="status-block-text">電話での来院予約受付時間外</p>';
    insertContentsOff += '  <div class="status-block-hours">';
    insertContentsOff += '    <a href="/forms/reservation/" class="link-nonUnderline"><span class="text">メールでの診療予約はこちら</span><span class="icon"><img src="/assets/img/common/icon-circle-right02.png"></span></a>';
    insertContentsOff += '  </div>';
    insertContentsOff += '</div>';
    var insertContentsOn = '';
    insertContentsOn += '<div class="On-hours">';
    insertContentsOn += '  <p class="status-block-text">ただいま診療予約受付中</p>';
    insertContentsOn += '  <p class="status-block-tel">TEL <span class="larger">075-762-1082</span></p>';
    insertContentsOn += '  <div class="status-block-hours js-status-hours">';
    insertContentsOn += '    <a href="/forms/reservation/" class="link-nonUnderline"><span class="text">メールでの診療予約はこちら</span><span class="icon"><img src="/assets/img/common/icon-circle-right02.png"></span></a>';
    insertContentsOn += '  </div>';
    insertContentsOn += '</div>';

    if(status == "●"){
      if (time.isBetween(beforeTime, afterTime)) {
        $(".js-status").append(insertContentsOn)
        // if(_m.format('a') == "am"){
        //   $(".js-status-text").text("ただいま診療予約受付中です。")
        //   $(".js-status-hours").text("9:30〜12:00（受付 9:00〜11:30）")
        // }else{
        //   $(".js-status-text").text("ただいま診療予約受付中です。")
        //   $(".js-status-hours").text("17:00〜19:30（受付 16:30〜19:00）")
        // }
      }else{
        $(".js-status").append(insertContentsOff)
        // if(_m.format('a') == "am"){
        //   $(".js-status-text").text("ただいま予約受付時間外です。")
        //   $(".js-status-hours").text("9:30〜12:00（受付 9:00〜11:30）")
        // } else{
        //   $(".js-status-text").text("ただいま予約受付時間外です。")
        //   $(".js-status-hours").text("17:00〜19:30（受付 16:30〜19:00）")
        // }
      }
    } else if(status == "▲"){
      if (time.isBetween(beforeTimeSecond, afterTimeSecond)) {
        $(".js-status").append(insertContentsOn)
        // if(_m.format('a') == "am"){
        //   $(".js-status-text").text("ただいま診療予約受付中です。")
        //   $(".js-status-hours").text("9:30〜12:00（受付 9:00〜11:30）")
        // }else{
        //   $(".js-status-text").text("ただいま診療予約受付中です。")
        //   $(".js-status-hours").text("17:00〜19:30（受付 16:30〜19:00）")
        // }
      }else{
        $(".js-status").append(insertContentsOff)
        // if(_m.format('a') == "am"){
        //   $(".js-status-text").text("ただいま予約受付時間外です。")
        //   $(".js-status-hours").text("9:30〜12:00（受付 9:00〜11:30）")
        // } else{
        //   $(".js-status-text").text("ただいま予約受付時間外です。")
        //   $(".js-status-hours").text("17:00〜19:30（受付 16:30〜19:00）")
        // }
      }
    } else{
        $(".js-status").append(insertContentsOff)
      // if(_m.format('a') == "am"){
      //   $(".js-status-text").text("ただいま予約受付時間外です。")
      //   $(".js-status-hours").text("9:30〜12:00（受付 9:00〜11:30）")
      // } else{
      //   $(".js-status-text").text("ただいま予約受付時間外です。")
      //   $(".js-status-hours").text("17:00〜19:30（受付 16:30〜19:00）")
      // }
    }
  }
  let init = function () {
    inheritance.framework.toolbox = new Util();
    new deviceState();
    new MagazineList();
    new BookSec();
    window.addEventListener("resize", function() {
      clearTimeout(window.resizedFinished), window.resizedFinished = setTimeout(function() {
        emitter.emit("window::smartresize")
      }, 200)
    });
    inheritance.framework.UI = new Ui;
    AOS.init({
      // mirror: true,
      once: true,
      // disable: function() {
      //   return window.innerWidth < 1025
      // }
    });

    $.ajax({
        url: '/assets/data/schedule.xml',
        dataType: 'xml',
        success: function(data) {
          // if($('.js-schedule_form').length){
          //   var insertContents = '';
          //   insertContents += '  <li class="s_week">';
          //   insertContents += '    <p><span>'+$(data).find('name').eq(0).text()+'</span></p>';
          //   insertContents += '    <p><span>'+$(data).find('name').eq(1).text()+'</span></p>';
          //   insertContents += '    <p><span>'+$(data).find('name').eq(2).text()+'</span></p>';
          //   insertContents += '    <p><span>'+$(data).find('name').eq(3).text()+'</span></p>';
          //   insertContents += '    <p><span>'+$(data).find('name').eq(4).text()+'</span></p>';
          //   insertContents += '    <p><span>'+$(data).find('name').eq(5).text()+'</span></p>';
          //   insertContents += '  </li>';
          //   insertContents += '  <li class="s_list s_list_1">';
          //   insertContents += '    <p><span class="'+($(data).find('am').eq(0).text()=="×" ? '' : 'redhot')+'">'+$(data).find('am').eq(0).text()+'</span></p>';
          //   insertContents += '    <p><span class="'+($(data).find('am').eq(1).text()=="×" ? '' : 'redhot')+'">'+$(data).find('am').eq(1).text()+'</span></p>';
          //   insertContents += '    <p><span class="'+($(data).find('am').eq(2).text()=="×" ? '' : 'redhot')+'">'+$(data).find('am').eq(2).text()+'</span></p>';
          //   insertContents += '    <p><span class="'+($(data).find('am').eq(3).text()=="×" ? '' : 'redhot')+'">'+$(data).find('am').eq(3).text()+'</span></p>';
          //   insertContents += '    <p><span class="'+($(data).find('am').eq(4).text()=="×" ? '' : 'redhot')+'">'+$(data).find('am').eq(4).text()+'</span></p>';
          //   insertContents += '    <p><span class="'+($(data).find('am').eq(5).text()=="×" ? '' : 'redhot')+'">'+$(data).find('am').eq(5).text()+'</span></p>';
          //   insertContents += '  </li>';
          //   insertContents += '  <li class="s_list s_list_2">';
          //   insertContents += '    <p><span class="'+($(data).find('surgery').eq(0).text()=="×" ? '' : 'redhot')+'">'+$(data).find('surgery').eq(0).text()+'</span></p>';
          //   insertContents += '    <p><span class="'+($(data).find('surgery').eq(1).text()=="×" ? '' : 'redhot')+'">'+$(data).find('surgery').eq(1).text()+'</span></p>';
          //   insertContents += '    <p><span class="'+($(data).find('surgery').eq(2).text()=="×" ? '' : 'redhot')+'">'+$(data).find('surgery').eq(2).text()+'</span></p>';
          //   insertContents += '    <p><span class="'+($(data).find('surgery').eq(3).text()=="×" ? '' : 'redhot')+'">'+$(data).find('surgery').eq(3).text()+'</p>';
          //   insertContents += '    <p><span class="'+($(data).find('surgery').eq(4).text()=="×" ? '' : 'redhot')+'">'+$(data).find('surgery').eq(4).text()+'</span></p>';
          //   insertContents += '    <p><span class="'+($(data).find('surgery').eq(5).text()=="×" ? '' : 'redhot')+'">'+$(data).find('surgery').eq(5).text()+'</p>';
          //   insertContents += '  </li>';
          //   insertContents += '  <li class="s_list s_list_3">';
          //   insertContents += '    <p><span class="'+($(data).find('pm').eq(0).text()=="×" ? '' : 'redhot')+'">'+$(data).find('pm').eq(0).text()+'</p>';
          //   insertContents += '    <p><span class="'+($(data).find('pm').eq(1).text()=="×" ? '' : 'redhot')+'">'+$(data).find('pm').eq(1).text()+'</span></p>';
          //   insertContents += '    <p><span class="'+($(data).find('pm').eq(2).text()=="×" ? '' : 'redhot')+'">'+$(data).find('pm').eq(2).text()+'</p>';
          //   insertContents += '    <p><span class="'+($(data).find('pm').eq(3).text()=="×" ? '' : 'redhot')+'">'+$(data).find('pm').eq(3).text()+'</p>';
          //   insertContents += '    <p><span class="'+($(data).find('pm').eq(4).text()=="×" ? '' : 'redhot')+'">'+$(data).find('pm').eq(4).text()+'</p>';
          //   insertContents += '    <p><span class="'+($(data).find('pm').eq(5).text()=="×" ? '' : 'redhot')+'">'+$(data).find('pm').eq(5).text()+'</p>';
          //   insertContents += '  </li>';
          //   $('.js-schedule_form').append(insertContents);
          // }

          var _m = moment();
          if(_m.format('dddd') == "Sunday"){
            judgment($(data).find('status').eq(0).text(),_m)
          } else if(_m.format('dddd') == "Monday"){
            judgment($(data).find('status').eq(1).text(),_m)
          } else if(_m.format('dddd') == "Tuesday"){
            judgment($(data).find('status').eq(2).text(),_m)
          } else if(_m.format('dddd') == "Wednesday"){
            judgment($(data).find('status').eq(3).text(),_m)
          } else if(_m.format('dddd') == "Thursday"){
            judgment($(data).find('status').eq(4).text(),_m)
          } else if(_m.format('dddd') == "Friday"){
            judgment($(data).find('status').eq(5).text(),_m)
          } else if(_m.format('dddd') == "Saturday"){
            judgment($(data).find('status').eq(6).text(),_m)
          }
        }
    });
  };
  return {
    init: init
  };
})(window);

//init読み込み
window.onload = Front.init;