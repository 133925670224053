import normalizeWheel from 'normalize-wheel';
export default class Ui {
  constructor() {
    this.checkNum = 0;
    this.checks = null;
    this.yesCount = 0;
    this.noCount = 0;

    this.heightUI = inheritance.$master.querySelector(".ui").getBoundingClientRect().height;
    this.navMobile = document.getElementById('nav-mobile');
    this.$jsPagetop = $(".jsPagetop")
    this.DIDSCROLL = false;
    this.delta = 5;
    this.lastScrollYPosition = 0;
    this.wheelEvent = inheritance.framework.toolbox.wheelSupport();
    this.navMobileFn();
    this.formFn();
    this.modalFn();
    this.carouselFn();
    this.events();
    this.watcher();
  }
  carouselFn(){
    var arrChangeCnt = new Array();
    var boxAime;
    let int;

    arrChangeCnt.push(boxAime = setInterval(boxChange, 4000));
    function boxChange(){
      $('.carousel.carousel-slider').carousel('next');
      $('.carousel.carousel-slider--secondary').carousel('next');
    }

    function run(){
      if (arrChangeCnt.length > 0) {
         clearInterval(arrChangeCnt.shift());
      }
      arrChangeCnt.push(boxAime = setInterval(boxChange, 4000));
    }
    function stop(){
      if (arrChangeCnt.length > 0) {
         clearInterval(arrChangeCnt.shift());
      }
    }

    $(".carousel").on('mouseenter', function() {
      if($(this).hasClass("scrolling")){
        stop()
        return false;
      }
    });
    $(".carousel").on('mouseleave', function() {
      run()
      return false;
    });
    $(".carousel").on('touchstart', function() {
      if($(this).hasClass("scrolling")){
        stop()
        return false;
      }
    });
    $(".carousel").on('touchend', function() {
      run()
      return false;
    });
    let subloadFlg=false
    let changeSlide = function(index, cl) {
      cl = (typeof cl == undefined) ? '.carousel.carousel-slider' : cl;
      $(cl).carousel('set', index);
    }
    $('.carousel.carousel-slider').carousel({
      fullWidth: true,
      padding:40,
      indicators: true,
      onCycleTo: function(data){
        if(subloadFlg == true){
          changeSlide($('.carousel.carousel-slider .carousel-item').index(data), '.carousel.carousel-slider-sub');
        }
      }
    });
    $('.carousel.carousel-slider-sub').carousel({
      fullWidth: true,
      dist: 0,
      indicators: false,
      onCycleTo: function(data){
        subloadFlg = true;
        changeSlide($('.carousel.carousel-slider-sub .carousel-item').index(data), '.carousel.carousel-slider');
      }
    });


    // let Secondary;
    // Secondary = setInterval(function() {
    //   $('.carousel.carousel-slider--secondary').carousel('next');
    // }, 4000);
    //
    // function run(){
    //   Secondary = setInterval(function(){
    //       $('.carousel.carousel-slider--secondary').carousel('next');
    //   }, 4000);
    // }
    // function stop(){
    //   clearInterval(Secondary);
    // }
    $('.carousel.carousel-slider--secondary').carousel({
      fullWidth: true,
      dist: 0,
      padding:40,
      indicators: true,
      onCycleTo: function(data){

      }
    });

  }
  navMobileFn() {
    var _this = this;
    var isOpen = false;
    var options = {
      edge:'right',
      onOpenStart: function () {
        isOpen = true;
        TweenMax.to($(".sidenav-close"), 0.3, {autoAlpha:1})
        $(".navbar-fixed").addClass("sidenav-open")
      },
      onCloseStart: function () {
        isOpen = false;
        TweenMax.to($(".sidenav-close"), 0.3, {autoAlpha:0})
        $(".navbar-fixed").removeClass("sidenav-open")
      }
    }
    var instances = M.Sidenav.init(_this.navMobile, options);

    $('.sidenav-close').on('click',function(){
      if(isOpen == true){
        $('.sidenav-overlay').trigger('click');
      }
    });
    $(".sidenav a").on('click',function(){
      if(isOpen == true){
        $('.sidenav-overlay').trigger('click');
      }
    });

    emitter.on("window::smartresize", function() {
      var windowWidth = window.innerWidth;
      if (windowWidth > 1025) {
        if(isOpen == true){
          $('.sidenav-overlay').trigger('click');
        }
      }
    })
  }
  formFn() {
    $('select').formSelect();

    var _this = this;
    this.day_of_week_day = $('select.day_of_week_day');
    this.day_of_week_content = $('select.day_of_week_content');
    this.day_of_week_content_list = [];
    this.day_of_week_content.each(function(){
      _this.day_of_week_content_list.push($(this).clone());
    });

    this.day_of_week_day.on({
      change : function(){
        var tmpDay = $(this).val();
        var tmpId = $(this).data('id');
        var tmpContent = $(this).data('content-target');
        var $tmpContent = $("select[name='" + tmpContent + "']");
        var $tmpClone = _this.day_of_week_content_list[tmpId - 1];
        $tmpClone = $tmpClone.clone();

        if(tmpDay == '水' || tmpDay == '木' || tmpDay == '土'){
          $tmpClone.find("option[value='午後診']").remove();
          $tmpClone.find("option[value='どちらでも良い']").remove();
        }

        $tmpContent.html($tmpClone.html());
        $tmpContent.prop("disabled", false);
        $tmpContent = $tmpClone;
        $('select').formSelect();
      }
    });

    $('#try').click(function(){
       console.log("sssss");
      $('.day_of_week_content').prop("disabled", false);
      $('select').formSelect();
    });
  }
  modalFn() {
    $('.modal').modal({
      "opacity":1
    });
  }
  events(){
    let _this = this;
    // scrollTop
    this.$jsPagetop.on("click", function(e) {
      e.preventDefault()
      TweenMax.to(window, 0.4, { scrollTo: { y: 0, autoKill: false ,ease:Elastic.easeOut.config(1, 0.8)},
        onComplete: function () {
          return false;
        }
      });
    });

    // ページ内リンク
    $('a[href^=\\#]:not([href=\\#]):not(.nolink)').on("click", function(e) {
      if(this.hash){
        e.preventDefault()
        // 移動先となる要素を取得
        let target = $(this.hash);
        let offsetY = $(".nav-wrapper").innerHeight()
        if (!target.length) return ;
        // 移動先となる値
        let targetY = target.offset().top-offsetY;

        TweenMax.to(window, 0.4, { scrollTo: { y: targetY, autoKill: false },
          onComplete: function () {
            // ハッシュ書き換えておく
            window.history.pushState(null, null, this.hash);
            // デフォルトの処理はキャンセル
            return false;
          }
        });
      }
    });

    $('.check_btn').on("click", function(e) {
      e.preventDefault()
      _this.Check();
    });

    _this.touch={};
    inheritance.$body.addEventListener(this.wheelEvent, function(e) {
        var normalized = normalizeWheel(e);
        _this.direction = normalized.pixelY,
        _this.DIDSCROLL = true,
        emitter.emit("window::scrolling", normalized)
    }),
    document.addEventListener("touchstart", function(e) {
        _this.touch.start = e.targetTouches[0].pageY
    }),
    document.addEventListener("touchmove", function(e) {
        _this.touch.end = e.targetTouches[0].pageY,
        _this.touch.start > _this.touch.end ? _this.direction = 1 : _this.direction = -1,
        _this.DIDSCROLL = true
    })

  }
  Check(){
    var _this = this;
    this.checks = $('.qlist li');
    this.checkNum = this.checks.length;

    var checkInput, count, i, input, val, _i, _ref;
    count = 0;
    this.yesCount = 0;
    this.noCount = 0;
    for (i = _i = 1, _ref = this.checkNum; 1 <= _ref ? _i <= _ref : _i >= _ref; i = 1 <= _ref ? ++_i : --_i) {
      input = $('input[name="q' + i + '"]');
      checkInput = $('input[name="q' + i + '"]:checked');

      if (checkInput.length) {
        val = checkInput.val();
        if (val === '1') {
          this.yesCount += 1;
        } else {
          this.noCount += 1;
        }
        count += 1;
        input.parent().parent().parent().removeClass("blank");
      } else {
        input.parent().parent().parent().addClass("blank");
      }
    }

    // →「はい」２個まで＝result1.html
    // →「はい」３個〜４個＝result2.html
    // →「はい」５個以上＝result3.html
    if (count < this.checkNum) {
      let target = $(".section-question");
      let offsetY = $(".nav-wrapper").innerHeight()

      if (!target.length) return ;
      // 移動先となる値
      let targetY = target.offset().top-offsetY;
      TweenMax.to(window, 0.4, { scrollTo: { y: targetY, autoKill: false },
        onComplete: function () {

          // デフォルトの処理はキャンセル
          return false;
        }
      });
    } else {
      if (this.yesCount <= 2) {
        return location.href = '/anxiety/result1.html';
      } else if (this.yesCount > 2 && this.yesCount <= 4) {
        return location.href = '/anxiety/result2.html';
      } else {
        return location.href = '/anxiety/result3.html';
      }
    }
  }
  toggleShowHide() {
    var t = inheritance.framework.toolbox.scrollPosition().scrollY;

    if(t > this.lastScrollYPosition){
      if(t > this.heightUI ){
        inheritance.$html.classList.add("ui--show")
      }
    }

    if(t <= this.heightUI ){
      inheritance.$html.classList.remove("ui--show")
    }

    this.lastScrollYPosition = t

    // Math.abs(this.lastScrollYPosition - t) <= this.delta || (t > this.lastScrollYPosition ? (t > this.heightUI && inheritance.$html.classList.contains("ui--show") && 0 == inheritance.$html.classList.contains("mainnav--isOpened") && (inheritance.$html.classList.add("ui--animExit"), setTimeout(function() {
    //     inheritance.$html.classList.remove("ui--animExit")
    // }, 450)), inheritance.$html.classList.remove("ui--show")) : t + inheritance.framework.toolbox.getViewport().height < inheritance.$master.getBoundingClientRect().height && inheritance.$html.classList.add("ui--show"), this.lastScrollYPosition = t)
  }
  watcher() {
    var _this = this;
    this.raf = rAF(function() {
      _this.watcher()
    }),
    this.DIDSCROLL && (this.toggleShowHide(), this.DIDSCROLL = false);

    var e = inheritance.framework.toolbox.scrollPosition();
    e.scrollY > 0 ? 0 == inheritance.$html.classList.contains("v--isScrolling") && inheritance.$html.classList.add("v--isScrolling") : (1 == inheritance.$html.classList.contains("v--isScrolling") && inheritance.$html.classList.remove("v--isScrolling"), inheritance.$html.classList.remove("ui--show")), e.scrollY > this.heightUI ? inheritance.$html.classList.add("ui--sticky") : 0 == e.scrollY && inheritance.$html.classList.remove("ui--sticky")
  }

}